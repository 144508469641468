<template>
  <!-- Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <!-- left align -->
        <b-col
          md="6"
          lg="6"
        >
          <b-card
            title="Integrate your Google calendar with Hustleforge to automatically book meetings."
            class="mb-0"
          >
            <b-card-text>
              <ul>
                <li>Hustleforge, will automatically book a meeting when user requests a meeting.</li>
                <li>Create a calendar event with all the details with a google meet invite.</li>
              </ul>
              Requirements
              <ul>
                <li>A Google account with calendar enabled</li>
              </ul>
            </b-card-text>

            <div class="mt-2">
              <b-overlay
                :show="busy"
                rounded
                opacity="0.4"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="onHidden"
              >
                <!-- <b-button v-if="profile && !profile.sessionID"
                  ref="connectButton"
                  variant="primary" 
                  :disabled="busy"
                  @click="upgrade"
                >
                  Upgrade to Pro Account
                </b-button> -->
                <b-button v-if="profile && profile.googleToken"
                  ref="connectButton"
                  variant="primary" 
                  :disabled="busy"
                  @click="disconnectGoogle"
                >
                  Disconnect from Google
                </b-button>
                <!-- <b-button v-else
                  ref="connectButton"
                  variant="primary" 
                  :disabled="busy"
                  @click="connectGoogle"
                >
                  Connect with Google
                </b-button> -->

                <b-button v-else
                  ref="connectButton"
                  @click="connectGoogle"
                  style="background-color:transparent !important; border-color:transparent !important; padding:0px;" 
                >
                  <b-img
                    :src="require('@/assets/images/icons/btn_google_signin_light_normal_web@2x.png')"
                    alt="signin with google"
                  />
                </b-button>

    
              </b-overlay>
            </div>
            
          </b-card>
        </b-col>
        <b-col
          class="text-center mt-2"
          align-v="center"
          md="6"
          lg="6"
        >
          <b-row>
            <b-col
              md="4"
              lg="4"
              sm="4"
              xs="4"
            >
              <div class="profile-image mt-5">
                <b-avatar
                  size="96"
                  variant="light"
                  :src="require('@/assets/images/logo/logo.svg')"
                />
              </div>
            </b-col>
            <b-col
              md="1"
              lg="1"
              sm="1"
              xs="1"
              class="mt-3"
            >
              <feather-icon
                icon="PlusIcon"
                size="38"
                class="text-primary mt-4"
              />
            </b-col>
            <b-col
              md="5"
              lg="5"
              sm="5"
              xs="5"
            >
              <div class="profile-image mt-5">
                <b-avatar
                  size="96"
                  variant="light"
                  :src="require('@/assets/images/icons/googleCalendar.png')"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText, BButton, BAvatar, BOverlay, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getAuth } from "firebase/auth";
import { getFirestore, getDoc, doc, updateDoc} from 'firebase/firestore';

const auth = getAuth();
const db = getFirestore();

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle, 
    BCardText,
    BButton,
    BAvatar,
    BOverlay,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      busy: false,
      profile: null,
    }
  },
  async created() {
    // TODO move to a vue store!!!!
    const profileRes = await getDoc(doc(db, "profiles", auth.currentUser.displayName))
    if(!profileRes.exists) return
    this.profile = profileRes.data()
  },
  methods: {
    async connectGoogle(){
      this.busy = true
      window.open('/authorizeGoogleCal?state='+window.btoa(auth.currentUser.displayName), 'firebaseAuth', 'height=415,width=500')
      // integrations hack to refresh UI
      setTimeout(() => { this.$router.push({ name: 'integrations' }) }, 2000);
      this.busy = false
    },
    disconnectGoogle(){
      updateDoc(doc(db, "profiles", auth.currentUser.displayName ), {googleToken:null}, { merge: true })
      // Linkedin hack to refresh UI
      setTimeout(() => { this.$router.go() }, 1000);
    },
    upgrade(){
      this.$router.push('/pricing')
    },
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.connectButton.focus()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>

